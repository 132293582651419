<template>
	<!-- 步骤二-个人 -->
	<div class="stepTwoPerson-page-all">
		<div class="stepTwoPerson-pageTitle">个人讲师入驻</div>
		<div class="stepTwoPerson-pageLine"></div>
		<!-- 标签 -->
		<stepitem type="2"></stepitem>
		<!-- 填写信息正文 -->
		<div class="stepTwoPerson-pageForm">
			<el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
				<!-- 申请人身份信息 -->
				<div class="stepTwoPerson-pageFormTitle">
					<span></span>
					<span>申请人身份信息</span>
				</div>
				<el-form-item label="真实姓名" prop="truename">
					<el-input v-model="ruleForm.truename" maxlength="10" placeholder="需与身份证一致" class="stepTwoPerson-handleInput"></el-input>
				</el-form-item>
				<el-form-item label="身份证明" prop="IDcard_type">
					<el-select v-model="ruleForm.IDcard_type" placeholder="请选择身份认证方式">
						<el-option label="身份证" value="1"></el-option>
						<el-option label="驾驶证" value="2"></el-option>
						<el-option label="护照" value="3"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="证件号码" prop="IDcard">
					<el-input v-model="ruleForm.IDcard" maxlength="18" placeholder="请输入证件号码" class="stepTwoPerson-handleInput"></el-input>
				</el-form-item>
				<el-form-item label="证件照" prop="IDcardimg">
					<el-upload class="avatar-uploader" action="123" :show-file-list="false" name="images" accept="image/*" :on-success="handleAvatarSuccess"
					 :before-upload="beforeAvatarUpload">
						<el-image v-if="ruleForm.IDcardimg" :src="ruleForm.IDcardimg" class="avatar"></el-image>
						<div v-else>
							<i class="el-icon-plus avatar-uploader-icon"></i>
							<div>点击上传图片</div>
						</div>
					</el-upload>
					<div class="stepTwoPerson-uploadTips" style="width: 421px;">
						请上传手持证件的半身照<br>
						文件小于6M<br>
						支持JPG/PNG/BMP等格式图片
					</div>
					<img src="../../../assets/img/organize/IDcard.png" class="idcardIcon">
					<div class="stepTwoPerson-uploadTips1">
						1、手臂完整露出。<br>
						2、证件信息清晰可见。
					</div>
				</el-form-item>
				<el-form-item label="资格证书" prop="credentials">
					<div class="stepTwoPerson-uploadTips2">请上传<span style="color: #FB3752;">至少一项</span> 资格证明，证明越充足越有可能通过审核</div>
					<div class="stepTwoPerson-classType">
						<div v-for="(item,index) in datatype" :class="['stepTwoPerson-textType',which==item.id?'stepTwoPerson-textTypeIn':'']"
						 @click="choosewhich(item.id)">{{item.name}}</div>
					</div>
					<el-upload class="avatar-uploader uploadPosition" action="123" :show-file-list="false" name="images" accept="image/*"
					 :on-success="handleAvatarSuccess" :before-upload="beforeAvatarUpload1">
						<el-image v-if="ruleForm.credentials_img" :src="ruleForm.credentials_img" class="avatar"></el-image>
						<div v-else>
							<i class="el-icon-plus avatar-uploader-icon"></i>
							<div>点击上传图片</div>
						</div>
					</el-upload>
					<div class="stepTwoPerson-uploadTips3">
						请提交有效期内的教师资格证照片，<br>
						需确保头像和文字清晰<br>
						文件小于6M，支持JPG/PNG/BMP等格式图片
					</div>
					<el-input v-model="ruleForm.credentials_msg" maxlength="20" placeholder="请输入证书名称，如：语文教师资格证。" class="stepTwoPerson-handleInput1"></el-input>
				</el-form-item>

				<!-- 个人信息 -->
				<div class="stepTwoPerson-pageLine stepTwoPerson-mr"></div>
				<div class="stepTwoPerson-pageFormTitle">
					<span></span>
					<span>个人信息</span>
				</div>
				<el-form-item label="个人头像" prop="headimg">
					<el-upload class="avatar-uploader" style="float: left;" action="123" :show-file-list="false" name="images" accept="image/*"
					 :on-success="handleAvatarSuccess" :before-upload="beforeAvatarUpload2">
						<el-image v-if="ruleForm.headimg" :src="ruleForm.headimg" class="avatar"></el-image>
						<div v-else>
							<i class="el-icon-plus avatar-uploader-icon"></i>
							<div>点击上传图片</div>
						</div>
					</el-upload>
					<div class="stepTwoPerson-uploadTips4">
						请上传<span style="color: #FB3752;">居中清晰头像</span>，不合规范会审核不通过（查看图例）<br>
						尺寸不小于130X130，文件小于6M<br>
						支持JPG/PNG/BMP等格式图片<br>
						请上传本人真实头像，通过审核后将显示在老师主页，暂不提供修改
					</div>
				</el-form-item>
				<el-form-item label="主营类目" prop="cat_id">
					<el-cascader v-model="value" :options="options" @change="handleChange" placeholder="请选择分类"></el-cascader>
					<div class="stepTwoPerson-uploadTips4">注：主营类目一旦选定将不支持修改该分类与可开设课程类型一致</div>
				</el-form-item>
				<el-form-item label="邮箱" prop="email">
					<el-input v-model="ruleForm.email" placeholder="请填写邮箱" class="stepTwoPerson-handleInput"></el-input>
				</el-form-item>
				<el-form-item label="个人简介" prop="personal_info" class="stepTwoPerson-handleTextarea">
					<el-input type="textarea" maxlength="100" v-model="ruleForm.personal_info" rows="6"></el-input>
				</el-form-item>
				<el-form-item label="教学工作经历" class="stepTwoPerson-handleTextarea">
					<div class="stepTwoPerson-eduBox" v-for="item in ruleForm.teach_exp">
						<div class="stepTwoPerson-handlePicker">
							<el-date-picker type="date" placeholder="请选择开始日期" v-model="item.start_time" style="width: 200px;" value-format="yyyy-MM-dd"></el-date-picker>
							<span style="margin-right: 5px;">至</span>
							<el-date-picker type="date" placeholder="请选择结束日期" v-model="item.end_time" style="width: 200px;" value-format="yyyy-MM-dd"></el-date-picker>
						</div>
						<el-input v-model="item.desc" maxlength="23" placeholder="请描述教学工作经历"></el-input>
					</div>
					<div class="stepTwoPerson-addEduButton" @click="addEduButton()">
						<img src="../../../assets/img/organize/add.png" class="stepTwoPerson-addIcon">
						添加教学工作经历
					</div>
				</el-form-item>
				<el-form-item label="教学工作成果" class="stepTwoPerson-handleTextarea">
					<div class="stepTwoPerson-eduBox" v-for="item in ruleForm.teach_result">
						<div class="stepTwoPerson-handlePicker">
							<el-date-picker type="date" placeholder="请选择开始日期" v-model="item.start_time" style="width: 200px;"></el-date-picker>
							<span style="margin-right: 5px;">至</span>
							<el-date-picker type="date" placeholder="请选择结束日期" v-model="item.end_time" style="width: 200px;"></el-date-picker>
						</div>
						<el-input v-model="item.desc" maxlength="23" placeholder="请描述教学工作成果"></el-input>
					</div>
					<div class="stepTwoPerson-addEduButton" @click="addEduButton1()">
						<img src="../../../assets/img/organize/add.png" class="stepTwoPerson-addIcon">
						添加教学工作成果
					</div>
				</el-form-item>
				<el-form-item label="手机号码" prop="mobile">
					<el-input v-model="ruleForm.mobile" placeholder="请填写手机号码" maxlength="11" class="stepTwoPerson-handleInput stepTwoPerson-mr1"></el-input>
					<el-input v-model="codeData" placeholder="请填写验证码" maxlength="6" class="stepTwoPerson-mr1" style="width: 150px;"></el-input>
					<el-button @click="toVerify()">{{codetext}}</el-button>
				</el-form-item>
				<el-form-item label="承诺书" prop="commitment">
					<el-upload class="avatar-uploader" style="float: left;" action="123" :show-file-list="false" name="images" accept="image/*"
					 :on-success="handleAvatarSuccess" :before-upload="beforeAvatarUpload3">
						<el-image v-if="ruleForm.commitment" :src="ruleForm.commitment" class="avatar"></el-image>
						<div v-else>
							<i class="el-icon-plus avatar-uploader-icon"></i>
							<div>点击上传图片</div>
						</div>
					</el-upload>
					<div class="stepTwoPerson-uploadTips4">
						请填写完毕本页面的信息后，点击下载<span style="color: #00D0B0;">云课堂承诺书，</span><br>
						上传<span style="color: #00D0B0;">亲笔签名、公司公章</span>后的传扫描件<br>
						文件小于6M<br>
						支持JPG/PNG/BMP等格式图片
					</div>
				</el-form-item>
				<!-- 下一步按钮 -->
				<div class="stepTwoPerson-pageButton" @click="toNext()">提交资料</div>
			</el-form>
		</div>

	</div>
</template>

<script>
	import stepitem from '../../common/stepitem.vue';
	import axios from 'axios'
	export default {
		components: {
			stepitem
		},
		data() {
			return {
				// 表单
				ruleForm: {
					truename: '',
					IDcard_type: '',
					IDcard: '',
					IDcardimg: '',
					mobile: '',
					credentials: 1,
					credentials_img: '',
					credentials_msg: '',
					headimg: '',
					cat_id: '',
					email: '',
					personal_info: '',
					teach_exp: [{
						start_time: "",
						end_time: "",
						desc: ""
					}],
					teach_result: [{
						start_time: "",
						end_time: "",
						desc: ""
					}],
					commitment: '',
				},
				// 表单验证
				rules: {
					truename: [{
						required: true,
						message: '请输入真实姓名',
						trigger: 'blur'
					}],
					IDcard_type: [{
						required: true,
						message: '请选择身份认证方式',
						trigger: 'change'
					}],
					IDcard: [{
						required: true,
						message: '请输入证件号码',
						trigger: 'blur'
					}],
					IDcardimg: [{
						required: true,
						message: '请上传证件照',
						trigger: 'change'
					}],
					credentials: [{
						required: true,
						message: '请上传资格证书',
						trigger: 'change'
					}],
					headimg: [{
						required: true,
						message: '请上传头像',
						trigger: 'change'
					}],
					cat_id: [{
						required: true,
						message: '请选择主营类目',
						trigger: 'blur'
					}],
					email: [{
						required: true,
						message: '请填写邮箱',
						trigger: 'blur'
					}],
					personal_info: [{
						required: true,
						message: '请填写个人简介',
						trigger: 'blur'
					}],
					mobile: [{
						required: true,
						message: '请输入手机号码',
						trigger: 'blur'
					}],
					commitment: [{
						required: true,
						message: '请上传承诺书',
						trigger: 'change'
					}]
				},
				// 上传
				posts: "https://ykt.qdy2020.com/index/uploadimage",
				whichPic: 1,
				// 资格证书选择框
				which: 1,
				datatype: [{
						id: 1,
						name: "教师认证"
					},
					{
						id: 2,
						name: "高学历认证"
					},
					{
						id: 3,
						name: "专业认证"
					},
					{
						id: 4,
						name: "微博认证号"
					},
					{
						id: 5,
						name: "其他认证"
					},
				],
				// 下拉框
				value: "",
				value1: "",
				// 级联选择器
				value: [],
				options: [{
					value: 'zhinan',
					label: '指南',
					children: [{
						value: 'shejiyuanze',
						label: '设计原则',
						children: [{
							value: 'yizhi',
							label: '一致'
						}, {
							value: 'fankui',
							label: '反馈'
						}, {
							value: 'xiaolv',
							label: '效率'
						}, {
							value: 'kekong',
							label: '可控'
						}]
					}],
				}],
				// 验证码
				codeData: '',
				codetext: "手机验证码",
				tclick:"",
				timer:""

			};
		},
		created() {
			this.resId = this.$route.query.id
			this.getCateData()

		},
		beforeDestroy() {
			clearInterval(this.timer);
			this.value = "获取验证码"
		},
		methods: {
			// 提交按钮
			toNext() {
				if (
					!this.ruleForm.truename ||
					!this.ruleForm.IDcard_type ||
					!this.ruleForm.IDcard ||
					!this.ruleForm.IDcardimg ||
					!this.ruleForm.mobile ||
					!this.codeData ||
					!this.ruleForm.credentials ||
					!this.ruleForm.credentials_img ||
					!this.ruleForm.credentials_msg ||
					!this.ruleForm.headimg ||
					!this.ruleForm.cat_id ||
					!this.ruleForm.email ||
					!this.ruleForm.personal_info ||
					!this.ruleForm.teach_exp ||
					!this.ruleForm.teach_result ||
					!this.ruleForm.commitment) {
					this.$message.error("请认真完善所有信息")
					return
				}
				this.$post('user/userenter', {
					truename: this.ruleForm.truename,
					IDcard_type: this.ruleForm.IDcard_type,
					IDcard: this.ruleForm.IDcard,
					IDcardimg: this.ruleForm.IDcardimg,
					mobile: this.ruleForm.mobile,
					code: this.codeData,
					credentials: this.ruleForm.credentials,
					credentials_img: this.ruleForm.credentials_img,
					credentials_msg: this.ruleForm.credentials_msg,
					headimg: this.ruleForm.headimg,
					cat_id: this.ruleForm.cat_id,
					email: this.ruleForm.email,
					personal_info: this.ruleForm.personal_info,
					teach_exp: this.ruleForm.teach_exp,
					teach_result: this.ruleForm.teach_result,
					commitment: this.ruleForm.commitment
				}).then(response => {
					if (response.error == 0) {
						this.$message.success(response.msg);
						this.$router.push({
							path: '/stepThree',
							query: {
								e: 2
							}
						})
					} else {
						this.$message.error(response.msg);
					}
				});
			},
			// 获取主营类目
			getCateData() {
				this.$get('goodscate/getgoodscatetreelist', {}).then(response => {
					if (response.error == 0) {
						for (var i = 0; i < response.data.goodsCateTreeList.length; i++) {
							response.data.goodsCateTreeList[i].value = response.data.goodsCateTreeList[i].id
							response.data.goodsCateTreeList[i].label = response.data.goodsCateTreeList[i].cat_name
							for (var j = 0; j < response.data.goodsCateTreeList[i].children.length; j++) {
								response.data.goodsCateTreeList[i].children[j].value = response.data.goodsCateTreeList[i].children[j].id
								response.data.goodsCateTreeList[i].children[j].label = response.data.goodsCateTreeList[i].children[j].cat_name
								for (var k = 0; k < response.data.goodsCateTreeList[i].children[j].children.length; k++) {
									response.data.goodsCateTreeList[i].children[j].children[k].value = response.data.goodsCateTreeList[i].children[
										j].children[k].id
									response.data.goodsCateTreeList[i].children[j].children[k].label = response.data.goodsCateTreeList[i].children[
										j].children[k].cat_name
									if (k == (response.data.goodsCateTreeList[i].children[j].children.length - 1)) {
										this.options = response.data.goodsCateTreeList
									}
								}
							}
						}
					} else {
						this.$message.error(response.msg);
					}
				});
			},
			// 上传图片
			handleAvatarSuccess(res, file) {},
			beforeAvatarUpload(file) {
				this.postpicture(file, 1)
			},
			beforeAvatarUpload1(file) {
				this.postpicture(file, 2)
			},
			beforeAvatarUpload2(file) {
				this.postpicture(file, 3)
			},
			beforeAvatarUpload3(file) {
				this.postpicture(file, 4)
			},
			postpicture(file, whichPic) {
				let param = new FormData(); // 创建form对象
				param.append('images', file); // 将文件存入file下面
				let config = {
					header: {
						'content-type': 'multipart/form-data'
					},
				}
				axios.post(this.posts, param, config)
					.then(res => {
						if (whichPic == 1) {
							this.ruleForm.IDcardimg = res.data.data
						}
						if (whichPic == 2) {
							this.ruleForm.credentials_img = res.data.data
						}
						if (whichPic == 3) {
							this.ruleForm.headimg = res.data.data
						}
						if (whichPic == 4) {
							this.ruleForm.commitment = res.data.data
						}
					}).catch(err => {
						console.log(err)
					})
			},

			// 资格证书选择框
			choosewhich(id) {
				this.which = id
				this.ruleForm.credentials = this.which
			},

			// 新增教学工作经历
			addEduButton() {
				var newEdu = {
					start_time: "",
					end_time: "",
					desc: "",
				}
				this.ruleForm.teach_exp.push(newEdu)
			},

			// 新增教学工作成果
			addEduButton1() {
				var newEdu = {
					start_time: "",
					end_time: "",
					desc: "",
				}
				this.ruleForm.teach_result.push(newEdu)
			},

			// 选择分类按钮
			handleChange(e) {
				this.ruleForm.cat_id = e[2]
			},

			// 发送验证码
			toVerify() {
				if (this.tclick == 2) {
					return
				}
				this.$get('code/sendverifycode', {
					phone: this.ruleForm.mobile
				}).then(response => {
					if (response.error == 0) {
						//this.codeData = response.data.code
						this.tclick = 2
						this.codetext = 60
						this.timer = setInterval(() => {
							if (this.codetext == 0) {
								this.tclick = 1
								this.codetext = "再次获取"
								clearInterval(this.timer);
								return
							}
							this.codetext -- 
						}, 1000);
					} else {
						this.$message.error(response.msg);
					}
				});
			},


		}
	}
</script>

<style>
	.stepTwoPerson-page-all {
		width: 65%;
		height: auto;
		margin: 0 auto;
		position: relative;
		top: 40px;
		margin-bottom: 52px;
		background-color: white;
		font-family: 'PingFang SC', "Helvetica Neue", Helvetica, "microsoft yahei", arial, STHeiTi, sans-serif !important;
		overflow: hidden;
	}

	.stepTwoPerson-pageTitle {
		width: 100%;
		height: 130px;
		font-size: 48px;
		font-family: 'PingFang SC', "Helvetica Neue", Helvetica, "microsoft yahei", arial, STHeiTi, sans-serif;
		font-weight: 400;
		color: #151515;
		line-height: 130px;
		text-align: center;
	}

	.stepTwoPerson-pageLine {
		width: 100%;
		height: 1px;
		background: #E6E6E6;
	}

	.stepTwoPerson-pageForm {
		width: 90%;
		height: auto;
		margin: 0 auto;
	}

	.stepTwoPerson-pageFormTitle {
		width: 100%;
		height: 22px;
		margin-bottom: 20px;
	}

	.stepTwoPerson-pageFormTitle span:first-child {
		display: inline-block;
		width: 3px;
		height: 20px;
		background: #00D0B0;
		float: left;
	}

	.stepTwoPerson-pageFormTitle span:last-child {
		display: inline-block;
		font-size: 22px;
		font-family: 'PingFang SC', "Helvetica Neue", Helvetica, "microsoft yahei", arial, STHeiTi, sans-serif;
		font-weight: 400;
		color: #333333;
		line-height: 18px;
		margin-left: 10px;
	}

	.stepTwoPerson-handleInput {
		width: 224px;
	}

	.stepTwoPerson-handleInput1 {
		width: 400px;
		position: absolute;
		margin-top: -100px;
		margin-left: 170px;
	}

	.stepTwoPerson-handleTextarea {
		width: 500px;
	}

	.stepTwoPerson-handlePicker {
		display: flex;
		margin-bottom: 10px;
	}

	.stepTwoPerson-eduBox {
		width: 400px;
		height: auto;
		margin-bottom: 20px;
	}

	.stepTwoPerson-classType {
		width: 140px;
		height: 250px;
		background-color: #F6F6F6;
		text-align: center;
		line-height: 50px;
	}

	.stepTwoPerson-textType {
		width: 140px;
		height: 50px;
		font-size: 18px;
		font-family: 'PingFang SC', "Helvetica Neue", Helvetica, "microsoft yahei", arial, STHeiTi, sans-serif;
		font-weight: 400;
		color: #333333;
	}

	.stepTwoPerson-textTypeIn {
		width: 140px;
		height: 50px;
		background: #00D0B0;
		font-size: 18px;
		font-family: 'PingFang SC', "Helvetica Neue", Helvetica, "microsoft yahei", arial, STHeiTi, sans-serif;
		font-weight: 400;
		color: #FFFFFF;
	}

	.stepTwoPerson-addEduButton {
		width: 400px;
		height: 30px;
		background: #EEEEEE;
		border: 1px solid #CCCCCC;
		border-radius: 8px;
		text-align: center;
		line-height: 30px;
		margin-top: 10px;
	}

	.stepTwoPerson-addIcon {
		position: absolute;
		margin-left: -30px;
		margin-top: 3px;
		height: 22px;
		width: 22px;
	}

	.stepTwoPerson-pageButton {
		width: 146px;
		height: 54px;
		background: #00D0B0;
		font-size: 20px;
		font-family: 'PingFang SC', "Helvetica Neue", Helvetica, "microsoft yahei", arial, STHeiTi, sans-serif;
		font-weight: 400;
		color: #FFFFFF;
		line-height: 54px;
		text-align: center;
		margin: 66px 100px;
		cursor: pointer;
	}

	.stepTwoPerson-mr {
		margin: 20px 0;
	}

	.stepTwoPerson-mr1 {
		margin-right: 10px;
	}


	.avatar {
		width: 178px;
		height: 178px;
		display: block;
	}

	.stepTwoPerson-uploadTips {
		width: 200px;
		height: 90px;
		font-size: 14px;
		font-family: 'PingFang SC', "Helvetica Neue", Helvetica, "microsoft yahei", arial, STHeiTi, sans-serif;
		color: #ACACAC;
		position: absolute;
		margin-top: -130px;
		margin-left: 210px;
	}

	.idcardIcon {
		position: absolute;
		margin-top: -135px;
		margin-left: 440px;
		width: 198px;
		height: 124px;
	}

	.stepTwoPerson-uploadTips1 {
		width: 125px;
		height: 90px;
		font-size: 14px;
		font-family: 'PingFang SC', "Helvetica Neue", Helvetica, "microsoft yahei", arial, STHeiTi, sans-serif;
		color: #ACACAC;
		position: absolute;
		margin-top: -130px;
		margin-left: 650px;
	}

	.stepTwoPerson-uploadTips2 {
		font-size: 14px;
		font-family: 'PingFang SC', "Helvetica Neue", Helvetica, "microsoft yahei", arial, STHeiTi, sans-serif;
		font-weight: 400;
		color: #ACACAC;
	}

	.uploadPosition {
		position: absolute;
		margin-top: -240px;
		margin-left: 170px;
	}

	.stepTwoPerson-uploadTips3 {
		width: 293px;
		height: 76px;
		font-size: 14px;
		font-family: 'PingFang SC', "Helvetica Neue", Helvetica, "microsoft yahei", arial, STHeiTi, sans-serif;
		color: #ACACAC;
		position: absolute;
		margin-top: -230px;
		margin-left: 390px;
	}

	.stepTwoPerson-uploadTips4 {
		width: 421px;
		font-size: 14px;
		font-family: 'PingFang SC', "Helvetica Neue", Helvetica, "microsoft yahei", arial, STHeiTi, sans-serif;
		color: #ACACAC;
		display: inline-block;
		margin-left: 10px;
	}

	.stepTwoPerson-biaoqian2 {
		margin-left: -5px;
		position: absolute;
		z-index: 2;
		width: 343px;
		height: auto;
	}

	.stepTwoPerson-biaoqian1 {
		position: absolute;
		z-index: 1;
		width: 343px;
		height: auto;
	}

	i {
		margin-top: 0;
	}
</style>
